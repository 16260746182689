enum Social {
  REDDIT = "REDDIT",
  INSTAGRAM = "INSTAGRAM",
  FACEBOOK = "FACEBOOK",
  TWITTER = "TWITTER",
  DISCORD = "DISCORD",
  LINE = "LINE",
  VK = "VK",
  YOUTUBE = "YOUTUBE",
  TWITCH = "TWITCH",
}

export default Social;
