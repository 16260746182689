import React, { useContext, useEffect, useMemo, useState } from "react";
import RiotLocaleContext from "../../contexts/riot-locale";
import { Time } from "./style";

export interface TimeAgoProps {
  dateTime: Date | number;
  language?: string;
  className?: string;
  testId?: string;
}

const TimeAgo: React.FC<TimeAgoProps> = ({ dateTime, language, className, testId }) => {
  const localeContext = useContext(RiotLocaleContext);
  const locale = language ?? localeContext;

  const date = useMemo(() => new Date(dateTime), [dateTime]);

  const [timeRef, setTimeRef] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (!timeRef) return;
    timeRef.textContent = dateToRelative(date, new Date(), locale);
  }, [timeRef, date, locale]);

  return <Time ref={(r) => setTimeRef(r)} dateTime={date.toISOString()} className={className} data-testid={testId}/>;
};

const dateToRelative = (date: Date, current: Date, locale?: string): string => {
  const rtf = new Intl.RelativeTimeFormat(locale ? locale : undefined, {
    localeMatcher: "lookup",
    numeric: "auto",
    style: "long",
  });

  // in seconds
  const diff = (date.getTime() - current.getTime()) / 1000;
  const absDiff = Math.abs(diff);

  if (absDiff < 3600) return rtf.format(Math.round(diff / 60), "minutes");
  else if (absDiff < 3600 * 24) return rtf.format(Math.round(diff / 3600), "hours");
  else if (absDiff < 3600 * 24 * 10) return rtf.format(Math.round(diff / (3600 * 24)), "days");
  else if (absDiff < 3600 * 24 * 31) return rtf.format(Math.round(diff / (3600 * 24 * 7)), "weeks");
  else if (absDiff < 60 * 60 * 24 * 365) return rtf.format(Math.round(diff / ((60 * 60 * 24 * 365) / 12)), "months");
  else return rtf.format(Math.round(diff / (60 * 60 * 24 * 365)), "years");
};

export default TimeAgo;
