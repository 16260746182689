import Social from "../../enums/social";

import { ReactComponent as DiscordSvg } from "./discord.svg";
import { ReactComponent as FacebookSvg } from "./facebook.svg";
import { ReactComponent as InstagramSvg } from "./instagram.svg";
import { ReactComponent as RedditSvg } from "./reddit.svg";
import { ReactComponent as TwitterSvg } from "./twitter.svg";
import { ReactComponent as LineSvg } from "./line.svg";
import { ReactComponent as VkSvg } from "./vk.svg";
import { ReactComponent as YouTubeSvg } from "./youtube.svg";
import { ReactComponent as TwitchSvg } from "./twitch.svg";

export const socialSvgMap: {
  [social in Social]: React.FunctionComponent<React.SVGProps<HTMLOrSVGElement>>;
} = {
  [Social.DISCORD]: DiscordSvg,
  [Social.FACEBOOK]: FacebookSvg,
  [Social.INSTAGRAM]: InstagramSvg,
  [Social.REDDIT]: RedditSvg,
  [Social.TWITTER]: TwitterSvg,
  [Social.LINE]: LineSvg,
  [Social.VK]: VkSvg,
  [Social.YOUTUBE]: YouTubeSvg,
  [Social.TWITCH]: TwitchSvg,
};

export const socialNameMap: { [social in Social]: string } = {
  [Social.DISCORD]: "discord.title",
  [Social.FACEBOOK]: "facebook.title",
  [Social.INSTAGRAM]: "instagram.title",
  [Social.REDDIT]: "reddit.title",
  [Social.TWITTER]: "twitter.title",
  [Social.LINE]: "social-network.line.title",
  [Social.VK]: "vk.title",
  [Social.YOUTUBE]: "youtube.title",
  [Social.TWITCH]: "twitch.title",
};

export { default as DiscordUrl, ReactComponent as DiscordSvg } from "./discord.svg";
export { default as FacebookUrl, ReactComponent as FacebookSvg } from "./facebook.svg";
export { default as InstagramUrl, ReactComponent as InstagramSvg } from "./instagram.svg";
export { default as LinkUrl, ReactComponent as LinkSvg } from "./link.svg";
export { default as RedditUrl, ReactComponent as RedditSvg } from "./reddit.svg";
export { default as TwitterUrl, ReactComponent as TwitterSvg } from "./twitter.svg";
export { default as LineUrl, ReactComponent as LineSvg } from "./line.svg";
export { default as VkUrl, ReactComponent as VkSvg } from "./vk.svg";
export { default as YouTubeUrl, ReactComponent as YouTubeSvg } from "./youtube.svg";
export { default as TwitchUrl, ReactComponent as TwitchSvg } from "./twitch.svg";
